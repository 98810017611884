/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, forwardRef, createRef } from 'react';
import { Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap';
import moment from 'moment';

import isEmpty from 'lodash.isempty';
import DatePicker from 'react-datepicker';
import { displayErrorToast, displayInfoToast, displaySuccessToast } from 'helpers/toaster';

import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import ApolloClientSellerService from 'ApolloClientSellerService';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';

// import { useHistory } from 'react-router-dom';
import { UPDATE_DELIVERY_REMARKS } from 'views/orders/actions/orderActions';
import { useMutation } from '@apollo/client';
import MediaItem from './MediaItem';

const DeliveryRemarks = ({
  remarks = '',
  orderReference = '',
  rescheduleDate,
  deliveryDate,
  cancelRequest = false,
  hideCancelRequest = false,
  reschedulePending = false,
  canceled = false,
  isOutforDelivery = false,
  orderId = 0,
  deliveryAttachment = '',
  onSuccess = () => {},
  refetch = () => {},
  Activity = () => {
    return <></>;
  },
}) => {
  // const history = useHistory();

  const deliveryMedia = (deliveryAttachment && deliveryAttachment.split(';')) || [];

  const [commentInfo, setCommentInfo] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const textareaRef = useRef(null);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 7);
  console.log('🚀 ~ file: DocumentDetails.js:13 ~ DocumentDetails ~ commentInfo', commentInfo);

  const [documentInfo, setDocumentInfo] = useState([]);
  const hiddenFileInput = useRef([createRef()]);
  const handleUploadFile = () => {
    hiddenFileInput.current.click();
  };
  const onChangeFile = ({ target: { files } }) => {
    const newFile = { file: files?.[0] };
    const oldDocumentInfo = documentInfo || [];
    setDocumentInfo([...oldDocumentInfo, newFile]);
  };

  const [updateDeliveryRemarksFn] = useMutation(UPDATE_DELIVERY_REMARKS(), {
    client: ApolloClientUploadSellerService,
  });
  // useEffect(() => {
  //   if (remarks) {
  //     setCommentInfo(remarks);
  //   }
  // }, [remarks]);

  const submitCommentData = async () => {
    try {
      if (!isEmpty(commentInfo?.remarks)) {
        // const updateData = {
        //   remarks: commentInfo,
        //   orderReference,
        // };
        setSubmitting(true);
        const { data: dataUpdate, error } = await updateDeliveryRemarksFn({
          variables: { ...commentInfo, orderReference, orderId, attachments: documentInfo },
        });
        setSubmitting(false);
        if (!error) {
          if (dataUpdate) {
            displaySuccessToast('Updated Successfully...');
            onSuccess();
            // history.goBack();
            refetch();
          }
        } else {
          displayErrorToast('Something went wrong..');
        }
      } else {
        displayInfoToast('Please add remarks');
      }
    } catch (e) {
      setSubmitting(false);
      displayErrorToast('Something went wrong, please retry again...');
    }
  };

  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = String(event.target.value);

    setCommentInfo({ ...commentInfo, [targetName]: targetValue });
  };

  const onCheckChanged = (event) => {
    setCommentInfo({ ...commentInfo, isReschedule: event.target.checked });
  };

  const onCheckCancelRequest = (event) => {
    setCommentInfo({ ...commentInfo, cancelRequest: event.target.checked });
  };

  const DeliveryDate = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outline-primary" className="custom-input" onClick={onClick} ref={ref}>
      {value || 'Click to select a date'}
    </Button>
  ));

  const handleDateChange = (date) => {
    date.setHours(23);
    date.setMinutes(59);
    setCommentInfo({ ...commentInfo, deliveryDate: date });
  };

  const handleEdit = () => {
    if (rescheduleDate?.length) {
      const date = new Date(Number(rescheduleDate));
      date.setHours(23);
      date.setMinutes(59);
      setCommentInfo({ ...commentInfo, remarks, isReschedule: true, deliveryDate: date, cancelRequest });
    } else {
      setCommentInfo({ ...commentInfo, remarks, cancelRequest });
    }
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 500);
  };

  const removeAttachmentItem = (i) => {
    const newDocumentInfo = documentInfo.splice(documentInfo, i);
    setDocumentInfo(newDocumentInfo);
  };

  return (
    <>
      <Col className="">
        <div className="page-title-container mb-3">
          <Row>
            <Col xxl="8" className="mb-5 mb-xxl-0">
              <Card className="mb-2">
                <Card.Body>
                  {/* <h2 className="cta-2">Delivery Remarks</h2> */}
                  <If condition={!remarks?.length || commentInfo?.remarks?.length}>
                    <Row>
                      <Col>
                        <Form.Check
                          placeholder=""
                          name="request_reschedule"
                          label="Request to reschedule"
                          checked={commentInfo?.isReschedule}
                          disabled={isOutforDelivery}
                          onChange={(event) => onCheckChanged(event)}
                        />
                      </Col>
                      <Col className="d-flex">
                        <If condition={commentInfo?.isReschedule}>
                          <p style={{ width: '200px', marginTop: '8px' }}>New Delivery Date</p>
                          <DatePicker
                            width={40}
                            className="form-control"
                            autoComplete="off"
                            minDate={new Date()}
                            maxDate={endDate}
                            onChange={handleDateChange}
                            name="date"
                            dateFormat="dd/MM/yyyy"
                            selected={commentInfo?.deliveryDate}
                            customInput={<DeliveryDate />}
                          />
                        </If>
                      </Col>
                    </Row>
                    <If condition={!hideCancelRequest}>
                      <Row>
                        <Col>
                          <Form.Check
                            placeholder=""
                            name="request_cancel"
                            label="Request to cancel the order"
                            checked={commentInfo?.cancelRequest}
                            onChange={(event) => onCheckCancelRequest(event)}
                          />
                        </Col>
                      </Row>
                    </If>

                    <If condition={isOutforDelivery}>
                      <Alert variant="info" className="mt-2">
                        The order status needs to be changed to <b>'Undelivered'</b> in order to reschedule the order that is currently{' '}
                        <b>'Out for Delivery'</b>.
                      </Alert>
                    </If>
                    <div className="mb-3 filled">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Delivery Remarks"
                        style={{ resize: 'vertical' }}
                        name="remarks"
                        ref={textareaRef}
                        onChange={onInputChanged}
                        value={commentInfo.remarks || ''}
                      />
                    </div>
                    <Button variant="outline-primary" className="btn-icon btn-icon-end" onClick={submitCommentData} disabled={isSubmitting}>
                      {isSubmitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                      <span>Submit</span> <CsLineIcons icon="send" />
                    </Button>
                    <Button variant="outline-primary" className=" btn-icon btn-icon-only ms-1" onClick={() => handleUploadFile()}>
                      <input type="file" className="d-none" onChange={(e) => onChangeFile(e)} ref={hiddenFileInput} />
                      <CsLineIcons icon="attachment" />
                    </Button>
                    <If condition={commentInfo?.isReschedule}>
                      <Alert variant="danger" className="mt-2">
                        Important: The request to change the delivery date will be authorized by DanubeHome following confirmation with the customer. <br />
                        <small>Please use this functionality only when the customer has specifically requested a new delivery date.</small>
                      </Alert>
                    </If>
                  </If>
                  <If condition={remarks?.length && !commentInfo?.remarks?.length}>
                    <p>{remarks}</p>
                    <If condition={rescheduleDate?.length}>
                      <p>
                        Reschedule requested to {moment(Number(rescheduleDate)).format('DD-MM-YYYY')}
                        <span
                          className="light-badge  ps-2 pe-2 mt-2 light-bagdge-dager"
                          style={{ backgroundColor: 'rgba(212, 147, 76, 0.1)', color: '#d44c4c', borderRadius: '4px' }}
                        >
                          {Number(rescheduleDate) === Number(deliveryDate) ? 'APPROVED' : (reschedulePending && 'PENDING APPROVAL') || 'REJECTED'}
                        </span>
                      </p>
                    </If>
                    <If condition={cancelRequest}>
                      <p>
                        Requested to cancel
                        <span
                          className="light-badge  ps-2 pe-2 mt-2 light-bagdge-dager"
                          style={{ backgroundColor: 'rgba(212, 147, 76, 0.1)', color: '#d44c4c', borderRadius: '4px' }}
                        >
                          {canceled ? 'CANCELLED' : 'PENDING APPROVAL'}
                        </span>
                      </p>
                    </If>
                    <Button variant="outline-primary" onClick={handleEdit}>
                      Edit
                    </Button>
                  </If>
                  <If condition={deliveryMedia?.length}>
                    <div className="mt-4 d-flex flex-row flex-wrap">
                      {deliveryMedia?.map((media, index) => {
                        if (media) {
                          return <MediaItem media={{ url: `${media}`, name: `${index + 1} File` }} key={index} />;
                        }
                        return null;
                      })}
                    </div>
                  </If>
                  <If condition={documentInfo?.length}>
                    <div className="mt-4 d-flex flex-row flex-wrap">
                      {documentInfo?.map((media, index) => {
                        return (
                          <MediaItem
                            media={{ ...media, name: `${index + 1} ${media?.type || 'Attachment'}` }}
                            key={media.name}
                            removeMediaItem={() => removeAttachmentItem(index)}
                          />
                        );
                      })}
                    </div>
                  </If>
                </Card.Body>
              </Card>
            </Col>

            <Activity />
          </Row>
        </div>
      </Col>
    </>
  );
};

export default DeliveryRemarks;
