import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { CORE_COMPANY, MENU_BEHAVIOUR, MENU_PLACEMENT } from 'constants/index';
import { format } from 'date-fns';
import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import { fetchAllStatements, acknowledgePaymentReceipt } from 'views/statements/actions/statementActions';
import { fetchQaList } from 'views/messages/action/action';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';
import { GET_SUPPORT_TICKET_LIST } from 'views/catalog/actions/supportTicketActions';
import { setSupportTicketListResult } from 'messageSlice';
import NavUserMenu from './NavUserMenu';
import NavIconMenu from './NavIconMenu';
import MainMenu from './main-menu/MainMenu';
import NavLogo from './NavLogo';
import NavMobileButtons from './NavMobileButtons';
import { menuChangeAttrMenuAnimate, menuChangeCollapseAll } from './main-menu/menuSlice';
import NavLanguageSwitcher from './NavLanguageSwitcher';
import NavCountrySwitcher from './NavCountrySwitcher';

const DELAY = 80;

const Nav = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);

  const fetchSuportTickets = async () => {
    const { data } = await ApolloClientUploadSellerService.query({ query: GET_SUPPORT_TICKET_LIST(), variables: {} });
    const { listAllSupportTicket } = data || {};
    dispatch(setSupportTicketListResult(listAllSupportTicket));
  };
  useEffect(() => {
    fetchAllStatements({ params: {}, dispatch });

    if (currentUser?.sellerId) {
      fetchQaList({
        params: {
          start: 0,
          limit: 100,
          search_qry: '',
          seller_id: currentUser?.sellerId,
          date_from: '',
          date_to: '',
          orderBy: 'id',
        },
        dispatch,
      });
    }
    setInterval(() => {
      fetchSuportTickets();
    }, 5 * 60 * 60 * 1000);
    fetchSuportTickets();
  }, []);
  const { list } = useSelector((state) => state.statements);
  const { navClasses, placementStatus, behaviourStatus, attrMobile, menuPadding } = useSelector((state) => state.menu);
  const mouseActionTimer = useRef(null);

  // Vertical menu semihidden state showing
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseEnterDelay = () => {
    if (placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical && behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned && attrMobile !== true) {
      dispatch(menuChangeCollapseAll(false));
      dispatch(menuChangeAttrMenuAnimate('show'));
    }
  };

  // Delayed one that hides or shows the menu. It's required to prevent collapse animation getting stucked
  const onMouseEnter = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);

    mouseActionTimer.current = setTimeout(() => {
      onMouseEnterDelay();
    }, DELAY);
  };

  // Vertical menu semihidden state hiding
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseLeaveDelay = () => {
    if (placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical && behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned && attrMobile !== true) {
      dispatch(menuChangeCollapseAll(true));
      dispatch(menuChangeAttrMenuAnimate('hidden'));
    }
  };

  const onMouseLeave = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);
    mouseActionTimer.current = setTimeout(() => {
      onMouseLeaveDelay();
    }, DELAY);
  };
  const date = new Date();
  const statmentStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const statementEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const paymentDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const soaStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const soaEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  if (date.getDate() > 15) {
    statmentStartDate.setDate(16);
    soaEndDate.setDate(15);
    paymentDate.setDate(15);
    paymentDate.setMonth(paymentDate.getMonth() + 1);
  } else {
    soaStartDate.setDate(16);
    soaStartDate.setMonth(date.getMonth() - 1);
    soaEndDate.setMonth(date.getMonth() - 1);
    statementEndDate.setDate(15);
  }
  let showAlert = false;
  if (statementEndDate.getDate() - date.getDate() < 3) {
    showAlert = true;
  }
  const showSOAlert = false;
  const statementId = `${format(soaStartDate, 'dd-MM-yyyy')}_${format(soaEndDate, 'dd-MM-yyyy')}?generate_soa`;

  const pendingAcknowledgment = list?.find((statement) => statement.payment_processed_on && !statement.payment_received_on);

  const [waitingResponse, setWaitingResponse] = useState(false);

  const handleReceiptAcknowledgement = async () => {
    try {
      setWaitingResponse(true);
      const results = await acknowledgePaymentReceipt({
        params: {
          statementPeriodId: pendingAcknowledgment.statement_period_id,
        },
      });
      setWaitingResponse(false);
      if (results?.acknowledgePaymentReceipt?.success) {
        fetchAllStatements({ params: {}, dispatch });
        displaySuccessToast(`Payment Receipt acknowledged.`);
      } else {
        displayErrorToast(`Something went wrong while updating ${pendingAcknowledgment.statement_period_id}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  return (
    <>
      <div id="nav" className={classNames('nav-container d-flex', navClasses)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div
          className="nav-content d-flex"
          style={placementStatus.placementHtmlData === MENU_PLACEMENT.Horizontal && menuPadding ? { paddingRight: menuPadding } : {}}
        >
          <NavLogo />
          <NavLanguageSwitcher />
          <If condition={CORE_COMPANY === currentUser?.sellerId}>
            <NavCountrySwitcher />
          </If>
          <NavUserMenu />
          <NavIconMenu />
          <MainMenu />
          <NavMobileButtons />
        </div>
        <div className="nav-shadow" />
      </div>
      <If condition={date.getFullYear() === 2024}>
        <div className="mt-6 mb-n7">
          <Alert variant="danger" className="mb-0">
            <Alert.Heading as="h5">New year early collection!</Alert.Heading>
            Dear seller, wishing you all a happy and prosperous new year ahead. Requesting you to process all orders by <b>1PM on 31st December</b> and handover
            to the driver upon arrival to avoid delays due to new year evening traffic.
          </Alert>
        </div>
      </If>
      <If condition={showAlert}>
        <div className="mt-6 mb-n7">
          <Alert variant="danger" className="mb-0">
            Invoice submission deadline for the statement period {format(statmentStartDate, 'do MMM, yyyy')} - {format(statementEndDate, 'do MMM, yyyy')} is
            approaching. Please raise and submit your invoices by <b>{format(statementEndDate, 'do MMM yyyy')}, 11:59 PM.</b> Invoices raised after this
            deadline will not be considered for payment on {format(paymentDate, 'do MMM, yyyy')}.
          </Alert>
        </div>
      </If>
      <If condition={showSOAlert}>
        <div className="mt-6 mb-n7">
          <Alert variant="danger" className="mb-0">
            Payment processing for the statement period {format(soaStartDate, 'do MMM, yyyy')} - {format(soaEndDate, 'do MMM, yyyy')} is due. Please generate
            the SOA to start the payament processing.{' '}
            <b>
              <NavLink className="d-inline-block" to={`/statements/transactions/detail/${statementId}`}>
                Click here
              </NavLink>
            </b>{' '}
            to generate the SOA.
          </Alert>
        </div>
      </If>
      <If condition={pendingAcknowledgment}>
        <div className="mt-6 mb-n7">
          <Alert variant="success" className="mb-0">
            Acknowledgement for the payment of AED {pendingAcknowledgment.total_payout} against the statement period{' '}
            {pendingAcknowledgment.statement_period_from_date} - {pendingAcknowledgment.statement_period_end_date} is pending. Please acknowledge the payment to
            processs next statement.
            <Button
              size="sm"
              variant="outline-success"
              onClick={() => {
                handleReceiptAcknowledgement();
              }}
              disabled={waitingResponse}
              className="float-end ms-2"
            >
              {waitingResponse && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              Acknowledge Payment Received
            </Button>
          </Alert>
        </div>
      </If>
    </>
  );
};
export default React.memo(Nav);
