import { OMS_API } from 'constants/index';
import { setOrderListV2 } from 'orderSlice';
// import { setOrderDetailV2 } from 'orderSlice/OrderDetail';
// import { setOrderStatus } from 'orderSlice/OrderStatus';
import { setOrderCounts } from 'orderSlice/OrderCounts';
import { setOrderAWB } from 'orderSlice/OrderAWB';
import { setInvoiceStatus } from 'orderSlice/invoiceStatus';
import axios from 'axios';
import { getHeaders } from 'auth/utils';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { gql } from '@apollo/client';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';
import { GET_PRODUCT_DETAIL } from 'views/catalog/actions/productDetailActions';

const apiUrl = OMS_API.BASE_URL;
// const token = 'RGFudWJlQXBpOkRhbnViZUAyMDIyJA==';
// const config = {
//   headers: { Authorization: `Bearer ${token}` },
// };

const ORDER_LIST_GQL = gql`
  query orderList(
    $start: Int
    $limit: Int
    $order_status: [Int]
    $search: String
    $orderByField: String
    $order: String
    $download: String
    $startDate: Date
    $endDate: Date
    $invoicePending: Boolean
    $podPending: Boolean
    $deliveryDate: Date
    $filterByDelivery: FilterByDelivery
    $supplierDeliveryOnly: Boolean
  ) {
    orderList(
      start: $start
      limit: $limit
      order_status: $order_status
      search: $search
      orderByField: $orderByField
      order: $order
      download: $download
      startDate: $startDate
      endDate: $endDate
      invoicePending: $invoicePending
      podPending: $podPending
      deliveryDate: $deliveryDate
      filterByDelivery: $filterByDelivery
      supplierDeliveryOnly: $supplierDeliveryOnly
    ) {
      listOrders {
        order_id
        entity_id
        reference_no
        store_id
        status
        order_status
        status_date
        ls_status
        ls_reference
        full_name
        first_name
        last_name
        email
        address1
        address2
        city
        zip
        region
        country
        phone
        latitude
        longitude
        vat_no
        shipping_amt
        shipping_method
        assembly_amount
        cod_fee
        coupon_code
        discount_amt
        coupon_rule_id
        coupon_rule_description
        vat_amt
        sub_total
        commision_total
        grand_total
        currency
        paid
        payment_mode
        paid_amount
        paid_date
        gateway
        transaction_id
        payment_info
        delivery_mode
        delivery_proof
        is_flow_ls
        deliver_by
        created_at
        updated_at
        payment_invoice_id
        payment_invoice_doc
        invoice_approved
        invoice_approved_by
        supplier_id
        is_do_grn
        giftcard_number
        giftcard_discount
        wallet_amount
        ls_customer_id
        awb_no
        po_number
        invoice_no
        purchase_transaction_no
        do_grn_date
        is_flow_seller
        is_supplier_delivery
        order_creation_date
        delivery_completed_at
        cancelled_by_customer
        cancelled_by_seller
        processingTime
        deliveryTime
        delivery_invoice_no
        delivery_invoice_date
        delivery_invoice_doc
        delivery_info
        ls_grn_status
        ls_do_status
        delivery_sla
        expected_delivery_date
        delivery_remarks
        delivery_assigned_to
        items {
          sku
          partner_sku
          name
          item_status
          total_price
          image
          qty_ordered
          qty_returned
          own_delivery
          now_price
        }
        deliveryRemarks {
          comment
          deliveryDate
          reschedulePending
        }
      }
      listOrdersCount {
        totalPages
        excelLink
      }
      orderStatusSummary {
        order_status
        statusCount
      }
    }
  }
`;
const ORDER_DETAIL_GQL = gql`
  query orderDetail($orderReferenceNumber: String) {
    orderDetail(orderReferenceNumber: $orderReferenceNumber) {
      order_id
      entity_id
      reference_no
      store_id
      status
      order_status
      status_date
      ls_status
      ls_reference
      full_name
      first_name
      last_name
      email
      address1
      address2
      city
      zip
      region
      country
      phone
      alternate_phone
      latitude
      longitude
      vat_no
      shipping_amt
      shipping_method
      cod_fee
      coupon_code
      discount_amt
      coupon_rule_id
      coupon_rule_description
      vat_amt
      sub_total
      commision_total
      grand_total
      currency
      paid
      payment_mode
      paid_amount
      paid_date
      gateway
      transaction_id
      payment_info
      delivery_mode
      is_flow_ls
      deliver_by
      created_at
      updated_at
      payment_invoice_id
      payment_invoice_doc
      invoice_approved
      invoice_approved_by
      supplier_id
      is_do_grn
      giftcard_number
      giftcard_discount
      wallet_amount
      ls_customer_id
      awb_no
      po_number
      invoice_no
      invoice_date
      purchase_transaction_no
      do_grn_date
      is_flow_seller
      is_supplier_delivery
      order_creation_date
      delivery_completed_at
      cancelled_by_customer
      payment_processed_on
      payment_processed_by_member_name
      processingTime
      deliveryTime
      delivery_sla
      expected_delivery_date
      delivery_remarks
      delivery_reschedule_to
      reschedule_pending
      delivery_attachments
      requested_to_cancel
      delivery_info
      items {
        sku
        partner_sku
        name
        item_status
        image
        qty_ordered
        qty_returned
        total_price
        now_price
        actual_now_price
        item_commission
        own_delivery
        delivery_dt
      }
      transactions {
        header {
          title
          value
        }
        body {
          title
          value
        }
        footer {
          title
          value
        }
      }
      returns {
        return_id
        return_no
        order_id
        item_sku
        ordered_item_qty
        return_item_qty
        return_status
        return_reason
        return_by
        is_approved
        is_supplier_delivery
        created_at
        credit_note_url
        credit_note_date
        credit_note_number
        now_price
        name
        image
        item_commission
      }
    }
  }
`;

const ORDER_STATUS_TRACKING_INFO_GQL = gql`
  query orderStatusTrackingInfo($orderId: Int) {
    orderStatusTrackingInfo(orderId: $orderId) {
      order_id
      step_id
      action
      status_message
      created_at
      created_by_user_email
      created_by_user_name
    }
  }
`;

export const UPDATE_SELLER_ORDER_STATUS = () => gql`
  mutation updateSellerOrderStatus($order: OrderInputs) {
    updateOrderStatus(order: $order) {
      message
      success
    }
  }
`;

export const UPLOAD_POD = () => gql`
  mutation uploadPod($order: OrderInputs) {
    uploadPod(order: $order) {
      message
      success
    }
  }
`;

const ORDER_TRANSACTIONS_GQL = gql`
  query orderTransactions($orderReferenceNumber: String) {
    orderTransactions(orderReferenceNumber: $orderReferenceNumber) {
      transaction_id
      order_reference_no
      transaction_type
      transaction_value
      vat_rate
      total_vat
    }
  }
`;

export const GET_ORDER_WEIGHT_GQL = () => gql`
  query cartWeight($orderReference: String) {
    cartWeight(orderReference: $orderReference) {
      weight
      shippingFee
      message
      cbmRequired
      skuWeight {
        sku
        weight
        qty
      }
    }
  }
`;

export const DOWNLOAD_AWB = gql`
  query downloadAWB($awbNumber: String) {
    downloadAWB(awbNumber: $awbNumber) {
      awb
    }
  }
`;

export const UPDATE_DELIVERY_REMARKS = () => gql`
  mutation updateDeliveryRemarks(
    $orderReference: String
    $remarks: String
    $isReschedule: Boolean
    $deliveryDate: Date
    $cancelRequest: Boolean
    $orderId: Int
    $attachments: [MediaInput]
  ) {
    updateDeliveryRemarks(
      orderReference: $orderReference
      orderId: $orderId
      remarks: $remarks
      isReschedule: $isReschedule
      deliveryDate: $deliveryDate
      cancelRequest: $cancelRequest
      attachments: $attachments
    ) {
      message
      success
    }
  }
`;

export const downloadAWBDoc = async ({ params }) => {
  const results = await ApolloClientSellerService.query({ query: DOWNLOAD_AWB, variables: { ...params }, fetchPolicy: 'network-only', errorPolicy: 'ignore' });
  console.log('results', results);
  return results?.data?.downloadAWB;
};

export const fetchAllOrders = async ({ params, dispatch }) => {
  // const config = await getHeaders();
  const results = await ApolloClientSellerService.query({
    query: ORDER_LIST_GQL,
    variables: { ...params },
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  console.log('results', results);
  if (dispatch) {
    dispatch(setOrderListV2(results?.data));
  }
  return results?.data;
  // return axios
  //   .post(`${apiUrl}/order/order-list`, { ...params }, config)
  //   .then((response) => {
  //     dispatch(setOrderList(response?.data));
  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
};

export const getOrderDetails = async ({ params }) => {
  //  const config = await getHeaders();
  const results = await ApolloClientSellerService.query({
    query: ORDER_DETAIL_GQL,
    variables: { ...params },
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  console.log('results', results);
  return results?.data.orderDetail;
  // dispatch(setOrderListV2(results?.data));
  // return axios
  //   .post(`${apiUrl}/order/order-details`, { ...params }, config)
  //   .then((response) => {
  //     console.log(response, '------------');
  //     dispatch(setOrderDetail(response?.data));
  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
};

export const updateSellerOrderStatus = async ({ params }) => {
  const result = await ApolloClientUploadSellerService.mutate({
    mutation: UPDATE_SELLER_ORDER_STATUS(),
    variables: { order: { ...params } },
    fetchPolicy: 'network-only',
  });
  console.log(result);
  return result?.data?.updateOrderStatus;
  /* const config = await getHeaders();
  return axios
    .post(`${apiUrl}/order/order-status`, { ...params }, config)
    .then((response) => {
      dispatch(setOrderStatus(response?.data));
    })
    .catch((error) => {
      throw error;
    }); */
};
export const uploadPOD = async ({ params }) => {
  const result = await ApolloClientUploadSellerService.mutate({
    mutation: UPLOAD_POD(),
    variables: { order: { ...params } },
    fetchPolicy: 'network-only',
  });
  console.log(result);
  return result?.data?.uploadPod;
};

export const getOrderStatusCounts = async ({ params, dispatch }) => {
  const config = await getHeaders();
  return axios
    .post(`${apiUrl}/order/order-counts`, { ...params }, config)
    .then((response) => {
      dispatch(setOrderCounts(response?.data));
    })
    .catch((error) => {
      throw error;
    });
};

export const createOrderAWB = async ({ params, dispatch }) => {
  const config = await getHeaders();
  return axios
    .post(`${apiUrl}/awb/create`, { ...params }, config)
    .then((response) => {
      dispatch(setOrderAWB(response?.data));
    })
    .catch((error) => {
      throw error;
    });
};
export const downloadAWB = async ({ params, dispatch }) => {
  const config = await getHeaders();
  return axios
    .post(`${apiUrl}/awb/generatePdf`, { ...params }, config)
    .then((response) => {
      dispatch(setAWBdownload(response?.data));
    })
    .catch((error) => {
      throw error;
    });
};
export const uploadOrderInvoice = async ({ formData, dispatch }) => {
  const config = await getHeaders();
  return axios
    .post(`${apiUrl}/order/invoice-upload`, formData, { headers: { ...config.headers, 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      dispatch(setInvoiceStatus(response?.data));
    })
    .catch((error) => {
      throw error;
    });
};

export const generateInvoice = async (formData) => {
  //  const config = await getHeaders();
  const result = await axios
    .post(`${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/generate-invoice`, formData, {
      headers: { Authorization: `Bearer ${window.localStorage?.authToken}`, 'Content-Type': `multipart/form-data;` },
    })
    .then((response) => {
      // dispatch(setInvoiceStatus(response?.data));
      return response;
    })
    .catch((error) => {
      throw error;
    });
  return result;
};

export const getOrderStatusTrackingInfo = async ({ params }) => {
  const results = await ApolloClientSellerService.query({ query: ORDER_STATUS_TRACKING_INFO_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  return results?.data.orderStatusTrackingInfo;
};

export const getOrderTransactions = async ({ params }) => {
  const results = await ApolloClientSellerService.query({ query: ORDER_TRANSACTIONS_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  return results?.data.orderTransactions;
};

export const getOrderProductDetails = async ({ params }) => {
  const results = await ApolloClientUploadSellerService.query({ query: GET_PRODUCT_DETAIL(), variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  return results?.data.getProductDetail;
};
